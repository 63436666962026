var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('router-link',{staticClass:"home-link",attrs:{"to":{name:'skypes'}}},[_c('div',{staticClass:"home-link-date"},[_vm._v("Онлайн конференции")]),_c('div',{staticClass:"home-link-name"},[_vm._v("для групп")])]),_c('br'),_c('br'),_c('br'),_vm._m(0),_c('br'),_vm._m(1),_c('br'),_vm._m(2),_c('br'),_c('br'),_c('br'),_vm._m(3),_c('br'),_vm._m(4),_c('br'),_c('router-link',{staticClass:"home-link",attrs:{"to":{name:'links'}}},[_c('div',{staticClass:"home-link-date"},[_vm._v("Просветительские беседы")]),_c('div',{staticClass:"home-link-name"},[_vm._v("в записи")])]),_c('br'),_c('br'),_c('br'),_vm._m(5),_c('br'),_vm._m(6),_c('br'),_c('br'),_c('br'),_vm._l((_vm.days),function(i){return _c('router-link',{key:'block-' + i.date,staticClass:"home-link",attrs:{"to":{name:'day', params: { date: i.date }}}},[_c('div',{staticClass:"home-link-date"},[_vm._v(_vm._s(i.dateTxt))]),_c('div',{staticClass:"home-link-name"},[_vm._v(_vm._s(i.name))])])}),_vm._l((_vm.psaltirBlock),function(i,index){return _c('router-link',{key:'psaltir-' + index,staticClass:"home-link",attrs:{"to":{name:'psaltir', params: { block_id: i.id }}}},[_c('div',{staticClass:"home-link-psaltir-name"},[_vm._v(_vm._s(i.name))]),(i.desc)?_c('div',{staticClass:"home-link-psaltir-desc"},[_vm._v(_vm._s(i.desc))]):_vm._e()])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"home-link",attrs:{"href":"http://radio.zakonbozhiy.ru:8000/live.mp3","target":"_blank"}},[_c('div',{staticClass:"home-link-date"},[_vm._v("Онлайн-трансляция богослужения")]),_c('div',{staticClass:"home-link-name"},[_vm._v("общины Архангела Михаила")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"home-link",attrs:{"href":"http://radio.zakonbozhiy.ru:8010/pls.mp3","target":"_blank"}},[_c('div',{staticClass:"home-link-date"},[_vm._v("Онлайн-трансляция богослужения")]),_c('div',{staticClass:"home-link-name"},[_vm._v("общины в честь Покрова Пресвятой Богородицы")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"home-link",attrs:{"href":"http://radio.zakonbozhiy.ru:8010/kem.mp3","target":"_blank"}},[_c('div',{staticClass:"home-link-date"},[_vm._v("Онлайн-трансляция богослужения")]),_c('div',{staticClass:"home-link-name"},[_vm._v("общины вмц. Варвары")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"home-link",attrs:{"href":"https://pr.akafist.ru/src/app/akafist_1.0.0.apk","target":"_blank"}},[_c('div',{staticClass:"home-link-date"},[_vm._v("Помощник чтеца")]),_c('div',{staticClass:"home-link-name"},[_vm._v("приложение для платформы Android")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"home-link",attrs:{"href":"https://pr.akafist.ru/src/app/molitvy-offline.apk","target":"_blank"}},[_c('div',{staticClass:"home-link-date"},[_vm._v("Молитвы оффлайн")]),_c('div',{staticClass:"home-link-name"},[_vm._v("приложение для платформы Android")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"home-link",attrs:{"href":"https://pr.energogroup.org/notes/note/add","target":"_blank"}},[_c('div',{staticClass:"home-link-date"},[_vm._v("Подать записку")]),_c('div',{staticClass:"home-link-name"},[_vm._v("онлайн")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"home-link",attrs:{"href":"https://pr.energogroup.org/talks/talk","target":"_blank"}},[_c('div',{staticClass:"home-link-date"},[_vm._v("Задать вопрос")]),_c('div',{staticClass:"home-link-name"},[_vm._v("Священнику или в Духовный блок")])])
}]

export { render, staticRenderFns }