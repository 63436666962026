<template>
  <div class="home">
    <router-link  class="home-link"  :to="{name:'skypes'}" >
      <div class="home-link-date">Онлайн конференции</div>
      <div class="home-link-name">для групп</div>
    </router-link>
    <br><br><br>
    <a class="home-link" href="http://radio.zakonbozhiy.ru:8000/live.mp3" target="_blank">
      <div class="home-link-date">Онлайн-трансляция богослужения</div>
      <div class="home-link-name">общины Архангела Михаила</div>
    </a>
<!--    <br>-->
<!--    <a class="home-link" href="http://dobrover.com/" target="_blank">-->
<!--      <div class="home-link-date">Онлайн-трансляция богослужения</div>-->
<!--      <div class="home-link-name">общины Архангела Гавриила</div>-->
<!--    </a>-->
    <br>
    <a class="home-link" href="http://radio.zakonbozhiy.ru:8010/pls.mp3" target="_blank">
      <div class="home-link-date">Онлайн-трансляция богослужения</div>
      <div class="home-link-name">общины в честь Покрова Пресвятой Богородицы</div>
    </a>
    <br>
    <a class="home-link" href="http://radio.zakonbozhiy.ru:8010/kem.mp3" target="_blank">
      <div class="home-link-date">Онлайн-трансляция богослужения</div>
      <div class="home-link-name">общины вмц. Варвары</div>
    </a>
    <br><br><br>
    <a class="home-link" href="https://pr.akafist.ru/src/app/akafist_1.0.0.apk" target="_blank">
      <div class="home-link-date">Помощник чтеца</div>
      <div class="home-link-name">приложение для платформы Android</div>
    </a>
    <br>
    <a class="home-link" href="https://pr.akafist.ru/src/app/molitvy-offline.apk" target="_blank">
      <div class="home-link-date">Молитвы оффлайн</div>
      <div class="home-link-name">приложение для платформы Android</div>
    </a>
    <br>
    <router-link class="home-link" :to="{name:'links'}">
      <div class="home-link-date">Просветительские беседы</div>
      <div class="home-link-name">в записи</div>
    </router-link>
    <br><br><br>
    <a class="home-link" href="https://pr.energogroup.org/notes/note/add" target="_blank">
      <div class="home-link-date">Подать записку</div>
      <div class="home-link-name">онлайн</div>
    </a>
    <br>
    <a class="home-link" href="https://pr.energogroup.org/talks/talk" target="_blank">
      <div class="home-link-date">Задать вопрос</div>
      <div class="home-link-name">Священнику или в Духовный блок</div>
    </a>
    <br><br><br>
    <router-link class="home-link" v-for="i in days" :to="{name:'day', params: { date: i.date }}" :key="'block-' + i.date">
      <div class="home-link-date">{{ i.dateTxt }}</div>
      <div class="home-link-name">{{ i.name }}</div>
    </router-link>
    <router-link class="home-link" v-for="(i, index) in psaltirBlock" :to="{name:'psaltir', params: { block_id: i.id }}" :key="'psaltir-' + index">
      <div class="home-link-psaltir-name">{{ i.name }}</div>
      <div class="home-link-psaltir-desc" v-if="i.desc">{{ i.desc }}</div>
    </router-link>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: 'home',
    created () {
      document.title = "Помощник чтеца";
      this.$store.dispatch('getDays');
      this.$store.dispatch('getPsaltirBlocks');
    },
    computed: {
      ...mapState({
        days: state => state.days,
        psaltirBlock: state => state.psaltirBlock
      })
    },
  }
</script>

<style scoped lang="less">
  @import "../assets/vars";

  .home {
    .home-link {
      display: block;
      padding: 1rem;
      text-decoration: none;
      margin-bottom: 1rem;
      background-color: @color-h;
      color: @color-l;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }

      .home-link-date {
        font-size: 1.5rem;
        padding-bottom: .2rem;
        margin-bottom: .2rem;
        border-bottom: 1px solid @color-l;
      }

      .home-link-name {
        font-size: 1.2rem;
      }

      .home-link-psaltir-name {
        font-size: 1.5rem;
      }

      .home-link-psaltir-desc {
        padding-top: .2rem;
        margin-top: .2rem;
        border-top: 1px solid @color-l;
        font-size: 1.2rem;
      }
    }
  }
</style>
